import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

import { searchProductByKeyword } from '../actions/searchProductAction';
import Suggestions from './Suggestions';

import '../styles/search.scss';

const SearchComponent = ({ history, isDesktopSearch }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [searchBoxVisible, setSearchBoxVisible] = useState(!isDesktopSearch);
  const [searchOptions, setSearchOptions] = useState([]);

  const {
    searchedproducts,
    isSearching,
    isPopularKeywordSearchByFooterLink,
  } = useSelector((state) => state.searchProducts);

  const searchInput = useRef();

  useEffect(() => {
    if (!isSearching) {
      setSearchOptions(searchedproducts);
    }
  }, [searchedproducts, isSearching]);

  const handleSearchProduct = (event) => {
    if (!event) {
      return;
    }
    const searchKeyword = event.target.value;
    setSearchText(searchKeyword);
    if (searchKeyword && searchKeyword.length > 3) {
      setTimeout(() => {
        dispatch(searchProductByKeyword(searchKeyword));
      }, 1000);
    }
  };

  const handleSearchItemClick = (product) => {
    setSearchOptions([]);
    setSearchText('');
    history.push({
      pathname: `/products/${product.id}`,
      state: { product },
    });
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      setSearchOptions([]);
      setSearchText('');
      history.push('/keyword/' + e.target.value);
    } else if (e.keyCode === 27) {
      setSearchOptions([]);
      setSearchText('');
    }
  };

  let searchBoxClass = 'search-box';
  if (!isDesktopSearch) {
    searchBoxClass += ' mobile-search-box';
  }

  return (
    <>
      {searchBoxVisible ? (
        <div className={searchBoxClass}>
          <TextField
            type="search"
            className="search-text"
            placeholder="I'm looking for"
            margin="normal"
            variant="outlined"
            inputRef={searchInput}
            value={searchText}
            onChange={handleSearchProduct}
            onKeyDown={handleKeyPress}
            fullWidth
          />
          {!isPopularKeywordSearchByFooterLink ? (
            <Suggestions
              results={searchOptions}
              isSearching={isSearching}
              handleSearchItemClick={handleSearchItemClick}
            />
          ) : null}
        </div>
      ) : null}
      {!searchBoxVisible ? (
        <div className="search-icon-container">
          <SearchIcon
            className="search-icon"
            onClick={() => setSearchBoxVisible(true)}
          />
        </div>
      ) : null}
    </>
  );
};

export default withRouter(SearchComponent);
