import {
  EXECUTION_STATUS,
  SEARCH_PRODUCTS,
  CLEAR_SEARCH_PRODUCTS_STATE,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  searchedproducts: [],
  isSearching: false,
  isPopularKeywordSearchByFooterLink: false,
  isLoaded: false,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  let { payload } = action;
  const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;

  switch (action.type) {
    ////////////////////////////////////////////////////////////////////////////////////////
    case SEARCH_PRODUCTS + STARTED:
      return {
        ...state,
        searchedproducts: [],
        isSearching: true,
        isLoaded: false,
        isPopularKeywordSearchByFooterLink: false,
      };
    case SEARCH_PRODUCTS + FINISHED:
      const { isPopularKeywordSearchByFooterLink, products } = payload;
      return {
        ...state,
        searchedproducts: products,
        isPopularKeywordSearchByFooterLink: isPopularKeywordSearchByFooterLink
          ? isPopularKeywordSearchByFooterLink
          : false,
        isSearching: false,
        isLoaded: true,
      };
    case SEARCH_PRODUCTS + ERROR:
      console.log('IN SEARCH_PRODUCTS_ERROR :::');
      return {
        ...state,
        isSearching: false,
        isLoaded: false,
        isPopularKeywordSearchByFooterLink: false,
        error: 'Error while getting all searched Products',
      };

    case CLEAR_SEARCH_PRODUCTS_STATE + FINISHED:
      return INITIAL_STATE;

    default:
      return state;
  }
}
