import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import { withFirebase } from '../utils/Firebase';

import { getAllCategories } from '../actions/categoryAction';
import TopHeader from './TopHeader';
import { NavLink } from 'react-router-dom';
import { WindMillLoading } from 'react-loadingg';

import SearchComponent from './SearchComponent';

import '../styles/header.scss';

const Header = ({ history }) => {
  const dispatch = useDispatch();

  const [scrolled, setScrolled] = useState(false);
  const [isMegaMenuShown, setShowMegaMenu] = useState(false);
  const [megaMenus, setMegaMenuList] = useState('');

  const { categories, areCategoriesLoading, isLoaded } = useSelector(
    (state) => state.categories
  );

  useEffect(() => {
    if (categories.length === 0 && !isLoaded && !areCategoriesLoading) {
      dispatch(getAllCategories());
    }
  }, [categories, areCategoriesLoading]);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50 && !scrolled) {
      setScrolled(true);
    } else if (offset < 50 && scrolled) {
      setScrolled(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  let headerClassName = 'header-top-area';
  if (scrolled) {
    headerClassName += ' fixed-header';
  }

  const handleShowMegaMenuContent = () => {
    let megaMenu = [];

    if (categories && categories.length > 0) {
      categories.forEach((category, index) => {
        const { id, name } = category;
        megaMenu.push(
          <Grid
            key={`${id}-${name}`}
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            className="mega-menu-item-container"
          >
            <div className="mega-menu-item">
              <NavLink
                key={index}
                to={{
                  pathname: `/categories/${category.pathname}/products`,
                  state: {
                    categoryName: `${category.name}`,
                    pathName: `${category.pathname}`,
                    categoryId: `${category.id}`,
                    brochure: `${category.brochure}`,
                    isOldBrochure: category.isOldBrochure
                      ? category.isOldBrochure
                      : false,
                  },
                }}
                exact={true}
                onClick={() => setShowMegaMenu(false)}
                //onMouseEnter={() => handleShowMegaMenuContent(id)}
              >
                {name}
              </NavLink>
            </div>
          </Grid>
        );
      });
    }
    setMegaMenuList(megaMenu);
    setShowMegaMenu(true);
  };

  return (
    <>
      <div className="header">
        <TopHeader />
        <Divider />
        <div className={headerClassName}>
          <div className="mega-menu menu">
            <NavLink
              key={'home'}
              className={'menu'}
              to={'/'}
              exact={true}
              onMouseEnter={() => setShowMegaMenu(false)}
            >
              Home
            </NavLink>

            <NavLink
              key={'products'}
              className="menu"
              to={'/home?showCatalog'}
              exact={true}
              onMouseEnter={() => handleShowMegaMenuContent()}
            >
              <div className="catalog">
                <span>Catalog</span>
                <ArrowDownIcon className="icon" />
              </div>
            </NavLink>

            <NavLink
              key={'about'}
              className={'menu'}
              to={'/about'}
              exact={true}
              onMouseEnter={() => setShowMegaMenu(false)}
            >
              About
            </NavLink>

            <NavLink
              key={'contact'}
              className={'menu'}
              to={'/contact'}
              exact={true}
              onMouseEnter={() => setShowMegaMenu(false)}
            >
              Contact
            </NavLink>

            {isMegaMenuShown && (
              <div
                className="mega-menu-panel"
                onMouseLeave={() => setShowMegaMenu(false)}
              >
                {areCategoriesLoading ? (
                  <div className="loading">
                    <WindMillLoading color="#ef7f1b" />
                  </div>
                ) : (
                  <Grid container>{megaMenus}</Grid>
                )}
              </div>
            )}
          </div>
          <SearchComponent className="desktop-search" isDesktopSearch={true} />
          <Button variant="contained" onClick={() => history.push('/contact')}>
            REQUEST A QUOTE
          </Button>
        </div>
        <SearchComponent className="mobile-search" isDesktopSearch={false} />
      </div>
    </>
  );
};

export default withRouter(withFirebase(Header));
