import React, { useState } from 'react';
import { getMenu } from '../utils/menu';
import { NavLink } from 'react-router-dom';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useLocation, withRouter } from 'react-router';
import { useSelector } from 'react-redux';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Drawer from '@material-ui/core/Drawer';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';

import logo from '../assets/reva_logo.png';
import '../styles/header.scss';

const TopHeader = ({ history }) => {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const activeMenuName = location.pathname;

  const { categories, areCategoriesLoading, isLoaded } = useSelector(
    (state) => state.categories
  );

  const toggleDrawer = (event, open) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const renderCatalogAccordian = (userMenu) => {
    return (
      <Accordion className="catalog-accordian">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <span>{userMenu.name}</span>
        </AccordionSummary>
        <AccordionDetails className="catalog-menu">
          {categories.map((category, index) => (
            <NavLink
              key={index}
              className="menu"
              to={{
                pathname: `/categories/${category.pathname}/products`,
                state: {
                  categoryName: `${category.name}`,
                  pathName: `${category.pathname}`,
                  categoryId: `${category.id}`,
                  brochure: `${category.brochure}`,
                },
              }}
              exact={true}
              onClick={(event) => toggleDrawer(event, false)}
            >
              {category.name}
            </NavLink>
          ))}
        </AccordionDetails>
      </Accordion>
    );
  };

  const logoClick = (event) => {
    history.push('/');
    toggleDrawer(event, false);
  };

  const list = () => {
    let userRoleMenu = getMenu('user');
    return (
      <div role="presentation" className="menu-container">
        <div className="logo">
          <p className="navbar-brand">
            <img src={logo} alt="Reva" onClick={(event) => logoClick(event)} />
            <CloseIcon
              className="close-icon"
              onClick={(event) => toggleDrawer(event, false)}
            />
          </p>
        </div>
        <List>
          {userRoleMenu.map((userMenu, index) =>
            userMenu.name === 'Catalog' ? (
              <ListItem button key={index} className="catalog-menu">
                {renderCatalogAccordian(userMenu)}
              </ListItem>
            ) : (
              <ListItem button key={index}>
                <NavLink
                  key={userMenu.link}
                  className={`menu ${
                    activeMenuName === `${userMenu.link}` ? 'active-menu' : ''
                  }`}
                  to={userMenu.link}
                  onClick={(event) => toggleDrawer(event, false)}
                  exact={true}
                >
                  {userMenu.name}
                </NavLink>
              </ListItem>
            )
          )}
        </List>
        <Divider />
      </div>
    );
  };

  return (
    <div className="top-header-container">
      <div className="logo">
        <img
          src={logo}
          alt="Reva Logo"
          onClick={() => history.push('/home')}
          className="logo"
        />
      </div>
      <div className="contact-container">
        <div className="social-media-container">
          <a
            href="https://www.facebook.com/RevaInternationalME/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FacebookIcon className="icon" />
          </a>
          <a
            href="https://instagram.com/revainternationalllc?igshid=l5vmhkfbqr6z"
            rel="noopener noreferrer"
            target="_blank"
          >
            <InstagramIcon className="icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/reva-international-llc"
            rel="noopener noreferrer"
            target="_blank"
          >
            <LinkedInIcon className="icon" />
          </a>
        </div>
        <div className="call-us-container">
          <span className="call-us-contact">Call Us:</span>
          <strong type="tel">
            <a href="tel://+97144214636">(+971) 4 4214636</a>
          </strong>
        </div>
      </div>

      <Drawer
        open={drawerOpen}
        className="menu-drawer"
        onClose={(event) => toggleDrawer(event, false)}
      >
        {list()}
      </Drawer>

      <MenuIcon
        className="collapsible-menu-icon"
        onClick={(e) => toggleDrawer(e, true)}
      />
    </div>
  );
};

export default withRouter(TopHeader);
