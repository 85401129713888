import {
  EXECUTION_STATUS,
  GET_CATEGORY,
  GET_CATEGORIES,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  category: null,
  categories: [],
  sortedCategories: [],
  categoriesMap: new Map(),
  orphanCategoriesMap: new Map(),
  isCategoryLoading: false,
  areCategoriesLoading: false,
  isLoaded: false,
};

export default function (state = INITIAL_STATE, action) {
  let { payload } = action;
  const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;

  switch (action.type) {
    ////////////////////////////////////////////////////////////////////////////////////////
    case GET_CATEGORY + STARTED:
      return {
        ...state,
        isCategoryLoading: true,
      };
    case GET_CATEGORY + FINISHED:
      return {
        ...state,
        category: payload,
        isCategoryLoading: false,
      };
    case GET_CATEGORY + ERROR:
      console.log('IN GET_CATEGORY_ERROR :::');
      return {
        ...state,
        category: {},
        isCategoryLoading: false,
        error: 'Error while getting category',
      };

    ////////////////////////////////////////////////////////////////////////////////////////
    case GET_CATEGORIES + STARTED:
      return {
        ...state,
        areCategoriesLoading: true,
      };
    case GET_CATEGORIES + FINISHED:
      return {
        ...state,
        categories: payload,
        isLoaded: true,
        areCategoriesLoading: false,
      };
    case GET_CATEGORIES + ERROR:
      console.log('IN GET_CATEGORIES_ERROR :::');
      return {
        ...state,
        areCategoriesLoading: false,
        error: 'Error while getting all categories',
      };

    default:
      return state;
  }
}
