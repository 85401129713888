import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

import {
  SEARCH_PRODUCTS,
  GET_PRODUCTS,
  GET_PRODUCT,
  EXECUTION_STATUS,
  CLEAR_SEARCH_PRODUCTS_STATE,
} from './actionTypes';

const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;

export function searchProductByKeyword(
  searchKeyword,
  isPopularKeywordSearchByFooterLink = false
) {
  return (dispatch) => {
    dispatch({
      type: SEARCH_PRODUCTS + STARTED,
    });
    dispatch({
      type: GET_PRODUCTS + STARTED,
    });
    firebase
      .firestore()
      .collection('products')
      .where('keywords', 'array-contains', searchKeyword.trim().toLowerCase())
      .get()
      .then((snapshot) => {
        let products = [];
        if (snapshot.size === 0) {
          dispatch({
            type: SEARCH_PRODUCTS + FINISHED,
            payload: {
              products,
              isPopularKeywordSearchByFooterLink,
            },
          });
          dispatch({
            type: GET_PRODUCTS + FINISHED,
            payload: [],
          });
        } else {
          snapshot.forEach((doc) => {
            const product = doc.data();
            product.id = doc.id;
            products.push(product);
          });
          dispatch({
            type: SEARCH_PRODUCTS + FINISHED,
            payload: {
              products,
              isPopularKeywordSearchByFooterLink,
            },
          });
          dispatch({
            type: GET_PRODUCTS + FINISHED,
            payload: products,
          });
        }
      })
      .catch((error) => {
        console.error('Error while getting all the PRODUCTS : ', error);
        dispatch({
          type: SEARCH_PRODUCTS + ERROR,
          error,
        });
        dispatch({
          type: GET_PRODUCTS + ERROR,
          error,
        });
      });
  };
}

export function searchProductByName(productName) {
  return (dispatch) => {
    dispatch({
      type: GET_PRODUCT + STARTED,
    });
    firebase
      .firestore()
      .collection('products')
      .where('name', '==', productName)
      .get()
      .then((snapshot) => {
        let product = {};
        if (snapshot.size === 0) {
          dispatch({
            type: GET_PRODUCT + FINISHED,
            payload: product,
          });
        } else {
          snapshot.forEach((doc) => {
            product = doc.data();
            product.id = doc.id;
          });
          dispatch({
            type: GET_PRODUCT + FINISHED,
            payload: product,
          });
        }
      })
      .catch((error) => {
        console.error(
          'Error while getting PRODUCT :',
          productName,
          ' Error: ',
          error
        );

        dispatch({
          type: GET_PRODUCT + ERROR,
          error,
        });
      });
  };
}

export function searchProductByNameAsKeyWord(productName) {
  return (dispatch) => {
    dispatch({
      type: GET_PRODUCT + STARTED,
    });
    firebase
      .firestore()
      .collection('products')
      .where('keywords', 'array-contains', productName.trim().toLowerCase())
      .get()
      .then((snapshot) => {
        let product = {};
        if (snapshot.size === 0) {
          dispatch({
            type: GET_PRODUCT + FINISHED,
            payload: product,
          });
        } else {
          snapshot.forEach((doc) => {
            product = doc.data();
            product.id = doc.id;
          });
          dispatch({
            type: GET_PRODUCT + FINISHED,
            payload: product,
          });
        }
      })
      .catch((error) => {
        console.error(
          'Error while getting PRODUCT :',
          productName,
          ' Error: ',
          error
        );

        dispatch({
          type: GET_PRODUCT + ERROR,
          error,
        });
      });
  };
}

export function clearProductSearch() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SEARCH_PRODUCTS_STATE + STARTED,
    });
  };
}
