//returns a path parameter from the given position of a path
export const getPathParameter = (pathname, position) => {
  const pathValues = pathname.split('/');
  return pathValues[position];
};

export function b64toBlob(dataURI) {
  let byteString = atob(dataURI.split(',')[1]);
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}

export function base64MimeType(encoded) {
  let result = null;

  if (typeof encoded !== 'string') {
    return result;
  }

  const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime && mime.length) {
    result = mime[1];
  }

  return result;
}

export function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isCharDigit(n) {
  return !!n.trim() && n * 0 == 0;
}

export function pascalCase(string) {
  return (
    string &&
    string.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase();
    })
  );
}

export function popularProducts() {
  return [
    'mastertop',
    'masterseal',
    'mastercast',
    'masterfinish',
    'masterprotect',
    'masterkure',
    'masterflame',
    'masteremaco',
    'mastertile',
    'masterbrace',
    'masterroc',
    'masterflow',
    'masterinject',
    'alpha dry base coat',
    'alpha',
    'ucrete',
    'chemicals',
    'epoxy',
    'silica',
    'floors',
    'concrete',
    'basf',
    'sealant',
    'waterproof',
    'firestop',
    'solvent free',
    'ceramic',
    'walls',
    'polymer',
    'grouting',
    'elastic',
    'grout',
    'ucrete fl',
    'vapour-proof',
  ];
}
