import React, { lazy, Suspense } from 'react';
import { hydrate, render } from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';
import { Route, Router, Switch } from 'react-router';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import Firebase, { FirebaseContext } from './utils/Firebase';
import { WindMillLoading } from 'react-loadingg';
import Backdrop from '@material-ui/core/Backdrop';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

import './styles/layout.scss';
import './index.css';

const Home = lazy(() => import('./screens/Home/Home'));
const About = lazy(() => import('./screens/About'));
const Products = lazy(() => import('./screens/Products'));
const ProductsByKeyword = lazy(() => import('./screens/ProductsByKeyword'));
const ProductDetails = lazy(() => import('./screens/ProductDetails'));
const Contact = lazy(() => import('./screens/Contact'));
const NotFound = lazy(() => import('./screens/NotFound'));

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

export const muiTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#ecc13d',
      main: '#202f5e',
      dark: '#d4a106',
    },
    secondary: {
      light: '#14a37f',
      main: '#ef7f1b',
      dark: '#4aedc4',
    },
  },
  appBar: {
    color: '#37517E',
    height: 50,
  },
});

const customHistory = createBrowserHistory();

const Root = () => {
  const classes = useStyles();

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <Suspense
          fallback={
            <Backdrop open={true} className={classes.backdrop}>
              <WindMillLoading color="#ef7f1b" />
            </Backdrop>
          }
        >
          <Router history={customHistory}>
            <ScrollToTop />
            <div className="layout-container">
              <Header />
              <div className="content-wrap">
                <Switch>
                  <Route exact path="/home" component={Home} />

                  <Route exact path="/contact" component={Contact} />

                  <Route exact path="/about" component={About} />

                  <Route
                    exact
                    path="/categories/:categoryId/products"
                    component={Products}
                  />

                  <Route
                    exact
                    path="/categories/:categoryId/products/:productId"
                    component={ProductDetails}
                  />

                  <Route
                    exact
                    path="/keyword/:productKeyword"
                    component={ProductsByKeyword}
                  />

                  <Route exact path="/products" component={Products} />

                  <Route
                    exact
                    path="/products/:category/:productName"
                    component={ProductDetails}
                  />

                  <Route
                    exact
                    path="/products/:productId"
                    component={ProductDetails}
                  />

                  {/* <Redirect from="/" to="/home" /> */}

                  <Route component={Home} />
                </Switch>
              </div>
              <Footer />
            </div>
          </Router>
        </Suspense>
      </MuiThemeProvider>
    </Provider>
  );
};

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(
    <FirebaseContext.Provider value={new Firebase()}>
      <Root />
    </FirebaseContext.Provider>,
    rootElement
  );
} else {
  render(
    <FirebaseContext.Provider value={new Firebase()}>
      <Root />
    </FirebaseContext.Provider>,
    rootElement
  );
}

registerServiceWorker();
