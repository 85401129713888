import React from 'react';

const Suggestions = (props) => {
  const { results, isSearching, handleSearchItemClick } = props;

  let options;
  if (results.length > 0) {
    options = results.map((result) => (
      <li key={result.id} onClick={() => handleSearchItemClick(result)}>
        {result.name}
      </li>
    ));
  } else if (isSearching) {
    options = <li>Loading...</li>;
  }
  return <ul>{options}</ul>;
};

export default Suggestions;
