import React from 'react';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';
import ArrowIcon from '@material-ui/icons/ArrowRight';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import HeartIcon from '@material-ui/icons/Favorite';

import { popularProducts } from '../utils';
import logo from '../assets/reva_logo.png';
import '../styles/footer.scss';

const Footer = ({ history }) => {
  const renderPopularProducts = () => {
    const popularProductsLink = popularProducts().map((product, index) => (
      <NavLink
        key={`${product}-${index}`}
        to={{
          pathname: `/keyword/${product}`,
        }}
        exact={true}
        className="keyword-link"
      >
        {product}
      </NavLink>
    ));
    return <div className="link-container">{popularProductsLink}</div>;
  };

  return (
    <div className="main-footer-area">
      <Grid
        container
        justify="center"
        spacing={5}
        className="footer-grid-container"
      >
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} className="no-gutters">
          <div className="contact-info">
            <img
              src={logo}
              alt="Reva Logo"
              onClick={() => history.push('/home')}
              className="logo"
            />

            <h4>(+971) 4 4214636</h4>
            <span>info@revaintl.com</span>
            <span>
              Office 407, Building 12, Bay Square, <br />
              Business Bay – Downtown Dubai, UAE
            </span>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} className="no-gutters">
          <div className="useful-links">
            <h5 className="widget-title">Useful Links</h5>
            <ul>
              <li>
                <ArrowIcon className="icon" />
                <NavLink key={'home'} className={'menu'} to={'/'} exact={true}>
                  Home
                </NavLink>
              </li>

              <li>
                <ArrowIcon className="icon" />
                <NavLink
                  key={'about'}
                  className={'menu'}
                  to={'/about'}
                  exact={true}
                >
                  About
                </NavLink>
              </li>

              <li>
                <ArrowIcon className="icon" />
                <NavLink
                  key={'contact'}
                  className={'menu'}
                  to={'/contact'}
                  exact={true}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="no-gutters">
          <div className="about">
            <h5 className="widget-title">About</h5>
            <span>
              <strong>Reva International</strong> is a global trading and
              distribution company with a network of offices in Africa, Asia,
              Gulf, and Europe. We leverage our worldwide presence along with
              sourcing and marketing competencies to deliver what we promise. We
              deal in Construction Chemical Products.
            </span>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="keywords-container no-gutters"
        >
          {renderPopularProducts()}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="no-gutters"
        >
          <div className="copywrite-content">
            <p>
              Copyright ©
              <script
                type="text/javascript"
                async=""
                src="https://www.google-analytics.com/analytics.js"
              ></script>
              <script type="text/javascript">
                document.write(new Date().getFullYear());
              </script>
              2020 All rights reserved to Reva International | This template is
              made with <HeartIcon className="heart-icon" /> by{' '}
              <a
                href="https://www.devarena.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                DevArena Solutions
              </a>
            </p>
            <div className="social-info">
              <a
                href="https://www.facebook.com/RevaInternationalME/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {' '}
                <FacebookIcon id="social-media-icon" />
              </a>
              <a
                href="https://instagram.com/revainternationalllc?igshid=l5vmhkfbqr6z"
                rel="noopener noreferrer"
                target="_blank"
              >
                {' '}
                <InstagramIcon id="social-media-icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/reva-international-llc"
                rel="noopener noreferrer"
                target="_blank"
              >
                {' '}
                <LinkedInIcon id="social-media-icon" />
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default Footer;
