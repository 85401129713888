import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

import { GET_CATEGORIES, GET_CATEGORY, EXECUTION_STATUS } from './actionTypes';

const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;

export function getAllCategories() {
  return (dispatch) => {
    dispatch({
      type: GET_CATEGORIES + STARTED,
    });
    firebase
      .firestore()
      .collection('categories')
      .get()
      .then((snapshot) => {
        let categories = [];
        if (snapshot.size === 0) {
          dispatch({
            type: GET_CATEGORIES + FINISHED,
            payload: [],
          });
        } else {
          snapshot.forEach((doc) => {
            const category = doc.data();
            category.id = doc.id;
            categories.push(category);
          });
          dispatch({
            type: GET_CATEGORIES + FINISHED,
            payload: categories,
          });
        }
      })
      .catch((error) => {
        console.error('Error while getting all the CATEGORIES : ', error);
        dispatch({
          type: GET_CATEGORIES + ERROR,
        });
      });
  };
}

export function getCategoryByName(categoryName) {
  return (dispatch) => {
    dispatch({
      type: GET_CATEGORY + STARTED,
    });
    firebase
      .firestore()
      .collection('categories')
      .where('name', '==', categoryName)
      .get()
      .then((snapshot) => {
        let category = {};

        snapshot.forEach((doc) => {
          const categoryData = doc.data();
          categoryData.id = doc.id;
          category = categoryData;
        });

        dispatch({
          type: GET_CATEGORY + FINISHED,
          payload: category,
        });
      })
      .catch((error) => {
        console.error('Error while getting the CATEGORY BY NAME : ', error);
        dispatch({
          type: GET_CATEGORY + ERROR,
        });
      });
  };
}

export function getCategoryByPathName(categoryPathName) {
  return (dispatch) => {
    dispatch({
      type: GET_CATEGORY + STARTED,
    });
    firebase
      .firestore()
      .collection('categories')
      .where('pathname', '==', categoryPathName)
      .get()
      .then((snapshot) => {
        let category = {};

        snapshot.forEach((doc) => {
          const categoryData = doc.data();
          categoryData.id = doc.id;
          category = categoryData;
        });

        dispatch({
          type: GET_CATEGORY + FINISHED,
          payload: category,
        });
      })
      .catch((error) => {
        console.error('Error while getting the CATEGORY BY NAME : ', error);
        dispatch({
          type: GET_CATEGORY + ERROR,
        });
      });
  };
}
