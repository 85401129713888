import {
  EXECUTION_STATUS,
  GET_PRODUCTS,
  GET_PRODUCT,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  product: null,
  products: [],
  sortedProducts: [],
  productsMap: new Map(),
  orphanProductsMap: new Map(),
  isCategoryLoading: false,
  areProductsLoading: false,
  isProductLoading: false,
  isLoaded: false,
};

export default function (state = INITIAL_STATE, action) {
  let { payload } = action;
  const { STARTED, FINISHED, ERROR } = EXECUTION_STATUS;

  switch (action.type) {
    ////////////////////////////////////////////////////////////////////////////////////////
    case GET_PRODUCTS + STARTED:
      return {
        ...state,
        products: [],
        areProductsLoading: true,
      };
    case GET_PRODUCTS + FINISHED:
      return {
        ...state,
        products: payload,
        isLoaded: true,
        areProductsLoading: false,
      };
    case GET_PRODUCTS + ERROR:
      console.log('IN GET_PRODUCTS_ERROR :::');
      return {
        ...state,
        areProductsLoading: false,
        error: 'Error while getting all products',
      };

    ////////////////////////////////////////////////////////////////////////////////////////
    case GET_PRODUCT + STARTED:
      return {
        ...state,
        product: null,
        isProductLoading: true,
      };
    case GET_PRODUCT + FINISHED:
      return {
        ...state,
        product: payload,
        isLoaded: true,
        isProductLoading: false,
      };
    case GET_PRODUCT + ERROR:
      console.log('IN GET_PRODUCT_ERROR :::');
      return {
        ...state,
        isProductLoading: false,
        error: payload.error,
      };

    default:
      return state;
  }
}
