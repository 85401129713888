export const EXECUTION_STATUS = {
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
};

/*USERS ACTION STARTS*/
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USERS = 'UPDATE_USERS';
/*USERS ACTION ENDS*/

/* CATEGORY ACTIONS STARTS */
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORY = 'GET_CATEGORY';
export const CLEAR_CATEGORY_STATE = 'CLEAR_CATEGORY_STATE';
/* CATEGORY ACTIONS ENDS */

/* PRODUCTS ACTIONS STARTS */
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCT = 'GET_PRODUCT';
export const CLEAR_PRODUCTS_STATE = 'CLEAR_PRODUCTS_STATE';
/* PRODUCTS ACTIONS ENDS */

/* SEARCH ACTION STARTS */
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const CLEAR_SEARCH_PRODUCTS_STATE = 'CLEAR_SEARCH_PRODUCTS_STATE';
/* SEARCH ACTION ENDS */
